<template>
    <div class="wrap">
        <!-- <div class="tabChange" ref="tabChanges">
            <div class="sel" @click="changeTab(0)">实物订单</div>
            <div @click="changeTab(1)">虚拟订单</div>
        </div> -->
        <component :is="needComponent"></component>
    </div>
</template>
<style scoped>
.tabChange{
    width: 7.5rem;
    height: 0.8rem;
    font-size: 0.28rem;
    line-height: 0.28rem;
    color:#000;
    display: flex;
    display: -webkit-flex;
}
.tabChange div{
    width: 50%;
    height: 0.8rem;
    font-size: 0.28rem;
    line-height: 0.28rem;
    color:#000;
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    align-items: center;
}
.sel{
    position: relative;
    width: 100%;
    height: 100%;
}
.sel::before{
    content: '';
    position: absolute;
    width: 0.8rem;
    height: 0.1rem;
    background: orangered;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
}
</style>
<script>
import orderList from "./orderList.vue";
import virtualList from './virtualList.vue'
export default {
  name: "allOrderList",
  data() {
    return {
      needComponent:'orderList'
    };
  },
  components: {
    orderList,
    virtualList
  },
  created() {
   
  },
  methods: {
    changeTab(index){
      console.log(this.$refs.tabChanges.getElementsByTagName('div'))
      var tabs = this.$refs.tabChanges.getElementsByTagName('div');
      tabs.forEach((item,index)=> {
        item.className =''
      });
      tabs[index].className = 'sel';
      this.needComponent = index=='0'?'orderList':'virtualList'
    }
  }
};
</script>