<template>
  <div class="goodsWrap" :class="{ loading: isLoading }">
    <!-- 加载状态 -->
    <template v-if="isLoading">
      <div class="goodsImg loading"></div>
      <div class="goodsInfo">
        <p class="name gapLoad">--</p>
        <p class="name gapLoad">--</p>
        <p class="name gapLoad">--</p>
        <p class="name gapLoad">--</p>
      </div>
    </template>
    <template v-else>
      <div class="goodsImg noImg" v-if="!imgUrl"></div>
      <img :src="imgUrl" alt="" v-else class="goodsImg" />
      <div class="goodItem">
        <div class="moduleItem">
          <p class="name">{{ goodsInfo.name }}</p>
          <p class="prize">
          ￥<span class="num">{{
            global.decimalPlace(goodsInfo.currentPrice, 2)
          }}</span>
          </p>
        </div>
        <div class="moduleItem">
          <p class="type" v-if="!!skuAttrTxt" :class="{ border: type == 1 }">
          {{ skuAttrTxt }}
          </p>
          <p class="goodsNum" v-if="isShowNum">
            <span class="sign">X</span>{{ buyCount }}
          </p>
        </div>
         <div class="moduleItem" v-if="detailTime(createdTime)">
          <p class="type">
            订单创建时间
          </p>
          <p class="goodsNum">{{ detailTime(createdTime) }}</p>
        </div>
      </div>
      <!-- <div class="goodsInfo">
        <p class="name">{{ goodsInfo.name }}</p>
        <p class="type" v-if="!!skuAttrTxt" :class="{ border: type == 1 }">
          {{ skuAttrTxt }}
        </p>
        <p class="type">订单创建时间</p>
        <p class="guarantee" v-if="type == 2">七天无理由退换</p>
      </div>
      <div class="goodsPrizeWrap">
        <p class="prize">
          ￥<span class="num">{{
            global.decimalPlace(goodsInfo.currentPrice, 2)
          }}</span>
        </p>
        <p class="goodsNum" v-if="isShowNum">
          <span class="sign">X</span>{{ buyCount }}
        </p>
         <p class="goodsNum">
          <span class="sign"></span>{{ createdTime }}
        </p>
      </div> -->
    </template>
  </div>
</template>

<script>
export default {
  name: "aOrderGoods",
  props: {
    //  是否显示数量
    isShowNum: {
      default: true
    },
    //  显示种类 1.显示商品属性名称：属性值 2.只显示属性值（用于订单列表）
    type: {
      default: 1
    },
    //  是否显示加载
    isLoading: {
      default: false
    },
    //  购买数量
    buyCount: {
      default: 1
    },
    //  产品图片【可能异步加载 由父组件单独赋值
    imgUrl: {
      default: ""
    },
    //  spu详情
    goodsInfo: {
      default: null
    },
    //  属性详情（可能为空）
    skuAttr: {
      default: null
    },
    createdTime:{
      default:null
    }
  },
  data() {
    return {
      skuAttrTxt: ""
    };
  },
  computed: {
    // totalPrice() {
    //   return (
    //     (this.goodsInfo &&
    //       this.goodsInfo.currentPrice &&
    //       this.global.decimalPlace(
    //         this.goodsInfo.currentPrice,
    //         // * this.buyCount,
    //         2
    //       )) ||
    //     0
    //   );
    // }
  },
  watch: {
    skuAttr(val) {
      this.setSkuAttrTxt(val);
    }
  },
  created() {
    this.setSkuAttrTxt();
  },
  methods: {
    changeUnit(fen) {
      var num = fen;
      num = fen * 0.01;
      num += "";
      var reg =
        num.indexOf(".") > -1
          ? /(\d{1,3})(?=(?:\d{3})+\.)/g
          : /(\d{1,3})(?=(?:\d{3})+$)/g;
      num = num.replace(reg, "$1");
      num = this.delDot(num);
      return num;
    },
    delDot(num) {
      var f = parseFloat(num);
      if (isNaN(f)) {
        return false;
      }
      var f = Math.round(num * 100) / 100;
      var s = f.toString();
      var rs = s.indexOf(".");
      if (rs < 0) {
        rs = s.length;
        s += ".";
      }
      while (s.length <= rs + 2) {
        s += "0";
      }
      return s;
    },
    detailTime(createdTime){
      console.log(createdTime)
      if(createdTime){
      var str = createdTime;
      return str.split(" ")[0]+' '+str.split(" ")[1].split('.')[0];
      }
     
    },
    //  渲染商品属性
    setSkuAttrTxt() {
      if (!this.skuAttr) this.skuAttrTxt = "";
      //  如果是对象
      if (typeof this.skuAttr == "object") {
        let skuAttrTxt = "";
        for (let id in this.skuAttr) {
          let val = this.skuAttr[id]["val"];
          skuAttrTxt +=
            (this.type == 1 ? this.skuAttr[id]["txt"] + ":" : "") +
            val[Object.keys(val)[0]] +
            " ";
        }
        this.skuAttrTxt = skuAttrTxt;
      } else {
        this.skuAttrTxt = this.skuAttr;
      }
    }
  }
};
</script>

<style lang="scss">
.goodsWrap {
  min-height: 160px;
  display: flex;
  .goodsImg {
    height: 160px;
    width: 160px;
    display: block;
    border-radius: 12px;
    object-fit: cover;
    margin-right: 25px;
  }
  .goodItem{
    margin-left: 20px;
    width: 500px;
    .moduleItem{
      display: flex;
      display: -webkit-flex;
      justify-content: space-between;
      align-items: center;
      font-size: 24px;
      .name{
         line-height: 30px;
         font-size: 24px;
          &.gapLoad {
        }
      }
      .prize{
        .num{
          font-size: 30px;
        }
      }
      .type {
      &.border {
        padding: 10px 15px;
        background: #f2f4f5;
        border-radius: 8px;
      }
      display: inline-block;
      font-size: 24px;
      line-height: 30px;
      color: #999;
      margin-top: 10px;
    }
      
    }
  }
  .goodsPrizeWrap {
    min-width: 90px;
    margin-left: 20px;
    line-height: 35px;
    text-align: right;
    font-size: 24px;
    .prize .num {
      font-size: 30px;
    }
    .goodsNum .sign {
      transform: scale(0.7);
      display: inline-block;
      line-height: 1;
    }
  }
  .goodsInfo {
    flex: 1;
    min-height: 160px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    word-break: break-all;
    font-size: 0;
    align-items: flex-start;
    .name {
      line-height: 30px;
      font-size: 24px;
      &.gapLoad {
        width: 100%;
      }
    }
    .type {
      &.border {
        padding: 10px 15px;
        background: #f2f4f5;
        border-radius: 8px;
      }
      display: inline-block;
      font-size: 24px;
      line-height: 30px;
      color: #999;
      margin-top: 10px;
    }
    .guarantee {
      color: #fa5220;
      font-size: 22px;
      line-height: 38px;
      height: 38px;
      padding: 0 10px;
      border-radius: 5px;
      background: rgba(250, 83, 32, 0.05);
      display: inline-block;
      margin-top: 10px;
      visibility: hidden;
    }
  }
}
</style>