<template>
  <div id="orderList">
    <div class="tabs">
      <div
        class="tab"
        :class="{ cur: orderStatus === null }"
        @click="switchTab(null)"
      >
        全部
      </div>
      <div
        class="tab"
        :class="{ cur: orderStatus == 0 }"
        @click="switchTab(0)"
      >
        待付款
      </div>
      <div
        class="tab"
        :class="{ cur: orderStatus == 1 }"
        @click="switchTab(1)"
      >
        待发货
      </div>
      <div
        class="tab"
        :class="{ cur: orderStatus == 3 }"
        @click="switchTab(3)"
      >
        待收货
      </div>
    </div>
    <div class="noData" v-if="curOrder.isInited && !curOrder.list.length">
      暂无相关订单
    </div>
    <div class="orderWrap" v-if="!curOrder.isInited">
      <div class="aOrder loading">
        <p class="state gapHidden"></p>
        <orderListGoods type="2" :isLoading="true"></orderListGoods>
        <div class="prizeTotal gapHidden"></div>
        <div class="orderOperation gapHidden"></div>
      </div>
    </div>
    <van-list
      class="orderWrap"
      v-if="!!curOrder.isInited"
      v-model="curOrder.listIsLoading"
      :finished="curOrder.loadFinised"
      :immediate-check="false"
      @load="getOrderList"
      :key="updateListKey"
    >
      <div
        class="aOrder"
        v-for="item in curOrder.list"
        :key="item.bizId"
        @click="toOrderDetail(item)"
      >
        <p class="state">{{ showOrderState(item.orderStatus) }}</p>
        <orderListGoods
          v-for="(goods, index) in item.productDataList"
          type="2"
          :buyCount="goods.buyNum"
          :imgUrl="goods.imgUrl"
          :goodsInfo="goods.goodsInfo"
          :skuAttr="goods.attribute"
          :createdTime='item.createdTime'
          :orderAmount='showPrice(item.orderAmount)'
          :actualAmount='showPrice(item.actualAmount)'
          :key="'1' + item.bizId + item.skuId + index"
        ></orderListGoods>
        <!-- <div class="prizeTotal">
          <span class="przie total">
            总价 <em class="yuan">￥</em>
            <span class="num">{{ showPrice(item.orderAmount) }}</span
            >,
          </span>
          <span class="przie discount">
            优惠-<em class="yuan">￥</em>
            <span class="num">30.00</span>
          </span>
          <span class="przie new">
            实付 <em class="yuan">￥</em>
            <span class="num">{{ showPrice(item.actualAmount) }}</span>
          </span>
        </div> -->
        <div class="orderOperation">
          <!-- todo 暂不考虑购买多个商品的情况 -->
          <button
            type="button"
            class="buybtn"
            @click.stop="
              toGoodsDetail(
                item.sceneNo,
                item.productDataList && item.productDataList[0],
                item.productDataList &&
                  item.productDataList[0] &&
                  item.productDataList[0].skuId
              )
            "
          >
            再下一单
          </button>
        </div>
      </div>
      <div class="backBtnWrap">
        <button type="button" class="backBtn" @click="toGoodList">
          返回商品列表
        </button>
      </div>
    </van-list>
    <!-- 推荐 -->
    <transition name="listshow">
      <div class="recommendPart" v-if="!!recommendList.length">
        <div class="title">为您推荐</div>
        <van-list
          class="goodsList"
          v-model="recommendListState.listIsLoading"
          :finished="recommendListState.loadFinised"
          @load="getRecommendList"
        >
          <div
            class="aGoods"
            v-for="item in recommendList"
            :key="item.spuNo"
            @click="toGoodsDetail(sceneNo, item)"
          >
            <div
              class="goodsImg"
              :class="{
                noImg:
                  !item.spuSnapshotGallery || !item.spuSnapshotGallery.imgUrl
              }"
            >
              <img
                :alt="item.name"
                v-if="item.spuSnapshotGallery && item.spuSnapshotGallery.imgUrl"
                :src="item.spuSnapshotGallery && item.spuSnapshotGallery.imgUrl"
              />
            </div>
            <p class="goodsName">{{ item.name }}</p>
            <div class="goodsInfo">
              <span class="saleSign">专享折后价</span>
              <div class="goodsPrizeWrap">
                <span class="goodsPrize">￥{{ item.currentPrice }}</span>
                <!-- <span class="buyNum">1776人已购买</span> -->
              </div>
            </div>
          </div>
        </van-list>
      </div>
    </transition>
  </div>
</template>

<script>
import orderListGoods from "@/components/goods/orderListGoods";

export default {
  name: "orderList",
  data() {
    return {
      sceneNo: this.$route.query.scence || this.$store.state.sceneNo || "", //  当前页面场景号  //  todo 推荐暂时不做
      // orderStatus: null, //  订单种类 null:全部 0：未付款，1：待付款，3：待发货
      orderStatus: null, //  订单种类 null:全部 0：未付款，1：已付款，3：待发货
      orderList: {
        null: {
          isInited: false, // 列表是否已经初始化
          curPage: 0,
          loadFinised: false, //  是否已加载完成 列表全部加载完成标识
          listIsLoading: false, //  列表是否正在加载
          list: []
        },
        0: {
          isInited: false,
          curPage: 0,
          loadFinised: false,
          listIsLoading: false,
          list: []
        },
        1: {
          isInited: false,
          curPage: 0,
          loadFinised: false,
          listIsLoading: false,
          list: []
        },
        3: {
          isInited: false,
          curPage: 0,
          loadFinised: false,
          listIsLoading: false,
          list: []
        }
      }, //  全部订单
      orderPageSize: 20, //  一次加载订单数量
      updateListKey: 0,
      curOrder: {
     
      },
      recommendList: [], //  推荐商品列表
      recommendListState: {
        curPage: 0,
        pageSize: 10,
        loadFinised: false, //  列表全部加载完成标识(初始设置为true是手动加载列表，防止组件会自动加载列表)
        listIsLoading: false //  列表是否正在加载
      }
    };
  },
  components: {
    orderListGoods
  },
  created() {
    const{needIndex} = this.$route.query;
    console.log(needIndex==null)
    if(needIndex){
      if(needIndex=='null'){
         this.getOrderList();
         return
      }
      this.switchTab(needIndex);
    }else{     
      this.getOrderList();
    }
  },
  methods: {
    //  切换订单状态
    switchTab(type) {
      console.log(type+'===================='+this.orderStatus)
      if (type === this.orderStatus) return;
      this.orderStatus = type;
      this.curOrder = this.orderList[String(this.orderStatus)];
      console.log(this.curOrder)
      if (!this.curOrder.isInited) this.getOrderList();
      this.updateListKey++;
    },
    //  获取订单列表
    getOrderList() {
      this.curOrder = this.orderList[String(this.orderStatus)];
      this.curOrder.curPage++;
      //  根据当前的orderType加载数据
      this.request
        // .get("/api/scm/order/list", {
        .get('/api/scm/order/v2/queryByUid',{
          orderStatus: this.orderStatus,
          page: this.curOrder.curPage,
          size: this.orderPageSize
        })
        .then((res) => {
          this.curOrder.isInited = true;
          this.curOrder.listIsLoading = false;
          this.curOrder.loadFinised = !res || res.length < this.orderPageSize; //  如果接口返回的数据小于分页数说明没有下一页了（接口没有返回总数 通过此方法来判断是否可以加载下一页）
          if (res.length) {
            res.forEach((item) => {
              item.productDataList &&
                item.productDataList.forEach((ii) => {
                  ii.goodsInfo = {
                    name: ii.spuName,
                    currentPrice: ii.skuPrice / 100 //  后端数据价格单位为分
                  };
                });
            });
            this.curOrder.list = this.curOrder.list.concat(res);
          }
          if (!this.recommendListState.curPage) this.getRecommendList();
        });
    },
    //  获取推荐列表
    getRecommendList() {
      // this.recommendListState.curPage++;
      // this.global.getGoodsList(
      //   this.sceneNo,
      //   this.recommendListState.curPage,
      //   this.recommendListState.pageSize,
      //   (res) => {
      //     this.isListInit = true;
      //     this.recommendListState.listIsLoading = false;
      //     this.recommendListState.loadFinised =
      //       !res || res.length < this.recommendListState.pageSize; //  如果接口返回的数据小于分页数说明没有下一页了（接口没有返回总数 通过此方法来判断是否可以加载下一页）
      //     if (res.length) {
      //       this.recommendList = this.recommendList.concat(res);
      //     }
      //   }
      // );
    },
    //  跳转至商品详情
    toGoodsDetail(sceneNo, item, repurchaseId) {
      console.log(item);
      this.$router.push({
        path: "/h/scm/mall/goodsDetail",
        query: {
          sceneNo: sceneNo,
          spuId: item.spuId,
          repurchaseId: repurchaseId || null
        }
      });
    },
    //  跳转至订单详情
    toOrderDetail(item) {
      this.$router.push({
        path: "/h/scm/order/orderDetail",
        query: {
          skuId: item.productDataList[0].skuId,
          bizId: item.bizId
        }
      });
    },
    //  返回商品列表
    toGoodList() {
      let from = this.$route.query.from;
      this.$router.push({
        name: 'hotSale',query:{scence:this.$route.query.sceneNo}
      });
    },
    //  显示价格
    showPrice(price) {
      let tmp = String(price);
      return (
        (tmp.length < 3 ? "0" : tmp.substr(0, tmp.length - 2)) +
        "." +
        this.global.getNumLastDoubleDigit(price)
      );
    },
    //  显示订单状态
    showOrderState(state) {
      let txt = "";
      switch (state) {
        case 0:
          txt = "买家未付款";
          break;
        case 1:
          txt = "买家已付款";
          break;
        case 2:
          txt = "订单已取消";
          break;
        case 3:
          txt = "卖家已发货";
          break;
        case 4:
          txt = "买家已签收";
          break;
        case 5:
          txt = "订单已退货、退款";
          break;
      }
      return txt;
    }
  }
};
</script>

<style lang="scss" scoped>
.listshow-enter-active {
  transition: all 0.5s ease;
}

.listshow-enter,
.listshow-leave-to {
  transform: translateY(10px);
  opacity: 0;
}
.tabs {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-between;
  background: #fff;
  // padding: 0 30px;
  .tab {
    margin: 0 35px;
    display: inline-block;
    font-size: 30px;
    line-height: 100px;
    position: relative;
    &.cur {
      color: #333;
      &::after {
        content: "";
        display: block;
        height: 8px;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 8px;
        background: linear-gradient(to right, #f23441, #f86340);
      }
    }
  }
}
.noData {
  border-top: 20px solid #efefef;
  text-align: center;
  padding: 360px 0 200px;
  font-size: 24px;
  color: #666;
  background: url(~@/assets/icons/nodata-icon.png) center 175px / 200px auto
    no-repeat;
  & + .orderWrap {
    padding-top: 0;
  }
}
.orderWrap {
  background: #efefef;
  padding: 20px 0;
  padding-top: 100px;
  .aOrder {
    padding: 0 30px;
    background: #fff;
    .state {
      line-height: 78px;
      height: 78px;
      text-align: right;
      color: #ff583d;
      font-size: 24px;
      &.close {
        color: #999;
      }
    }
    .prizeTotal {
      line-height: 40px;
      min-height: 40px;
      font-size: 26px;
      color: #888;
      margin: 40px 0 30px;
      text-align: right;
      .yuan {
        font-size: 24px;
      }
      .num {
        font-size: 28px;
        font-weight: 600;
      }
      .przie {
        display: inline-block;
        &.new {
          color: #000;
          margin-left: 20px;
        }
      }
    }
    .orderOperation {
      text-align: right;
      padding-bottom: 35px;
      font-size: 0;
      height: 50px;
      .buybtn {
        box-sizing: border-box;
        height: 50px;
        width: 148px;
        /* prettier-ignore */
        border: 1PX solid #ea3d39;
        border-radius: 50px;
        font-size: 24px;
        color: #ea3d39;
        background: #fff;
      }
    }
    & + .aOrder {
      margin-top: 20px;
    }
  }
}
.recommendPart {
  padding: 0 30px 30px;
  .title {
    padding: 20px 0;
    line-height: 50px;
    margin-bottom: 2px;
    font-size: 34px;
    color: #000;
    font-weight: bold;
  }
}
.goodsList {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .aGoods {
    width: 335px;
    overflow: hidden;
    background: #fff;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    border-radius: 12px;
    .goodsImg {
      height: 335px;
      position: relative;
    }
    .goodsName {
      margin: 10px 10px 10px 20px;
      height: 38px;
      line-height: 38px;
      font-size: 26px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .goodsInfo {
      padding: 0 20px 20px;
      font-size: 0;
      .returnSign {
        line-height: 38px;
        height: 38px;
        font-size: 20px;
        color: #888;
        background: #f2f4f5;
        border-radius: 5px;
        display: inline-block;
        padding: 0 15px;
        margin-bottom: 12px;
        &::after {
          content: "";
          display: block;
        }
      }
      .goodsPrizeWrap {
        margin-top: 10px;
        line-height: 34px;
        min-height: 34px;
        overflow: hidden;
        clear: both;
      }
      .goodsPrize {
        font-size: 30px;
        font-weight: bold;
        color: #ea3d39;
        float: left;
      }
      .buyNum {
        font-size: 22px;
        color: #999;
        float: right;
      }
    }
  }
}
.backBtnWrap {
  background: #fff;
  padding: 10px 0 54px;
  text-align: center;
  .backBtn {
    display: block;
    margin: 0 auto;
    height: 56px;
    color: #fff;
    width: 220px;
    background: #ff583d;
    font-size: 24px;
    border-radius: 56px;
  }
}
</style>